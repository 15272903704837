import type { ComponentType } from "react"
import React, { useState, useEffect } from "react"

const sha256 = (str) => {
    const encoder = new TextEncoder()
    const data = encoder.encode(str)
    return crypto.subtle.digest("SHA-256", data).then((hashArray) => {
        const hashHex = Array.from(new Uint8Array(hashArray))
            .map((byte) => byte.toString(16).padStart(2, "0"))
            .join("")
        return hashHex
    })
}

export function withProtectPage(Component: ComponentType): ComponentType {
    const hashedCorrectPassword =
        "386ebc0efd04339aa982703eb666c335d8458b8657343d9783185debf0bf0c26"
    // Encrypt your desired password using this link https://codebeautify.org/sha256-hash-generator
    return function withProtectPage(props) {
        const [password, setPassword] = useState("")
        const [showContent, setShowContent] = useState(false)
        const [errorMessage, setErrorMessage] = useState("")
        const [hoverStyle, setHoverStyle] = useState(linkStyle)
        const [BhoverStyle, setBackHoverStyle] = useState(BacklinkStyle)

        const handleSubmit = async (event) => {
            event.preventDefault()
            const hashedPassword = await sha256(password)

            if (hashedPassword === hashedCorrectPassword) {
                setShowContent(true)
                setErrorMessage("")
            } else {
                setErrorMessage("Incorrect password. Please try again.")
            }
        }

        return (
            <>
                {showContent ? (
                    <Component {...props} />
                ) : (
                    <form onSubmit={handleSubmit} style={formStyle}>
                        <div style={wrapperStyle}>
                            <div style={boxtitlex}>
                                <img
                                    src="https://framerusercontent.com/images/ZnaSUN4IdFnNFmU8XvdNYurV6s.png?scale-down-to=512"
                                    //Change your logo
                                    style={brandx}
                                />

                                <h1 style={titlex}>
                                    Hi! This project is protected.
                                </h1>
                                <p style={subtitlex}>
                                    If you want to see it,{" "}
                                    <a
                                        style={hoverStyle}
                                        onMouseEnter={() =>
                                            setHoverStyle(linkHoverStyle)
                                        }
                                        onMouseLeave={() =>
                                            setHoverStyle(linkStyle)
                                        }
                                        href="mailto:dlee6437@gmail.com"
                                    >
                                        send an e-mail
                                    </a>{" "}
                                    and get the password.
                                </p>
                            </div>
                            <div style={boxtitlex}>
                                <div style={boxform}>
                                    <input
                                        type="password"
                                        placeholder="Enter password here"
                                        style={inputStyle}
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                    {errorMessage && (
                                        <p style={errorMessageStyle}>
                                            {errorMessage}
                                        </p>
                                    )}
                                </div>
                                <div style={boxform}>
                                    <button type="submit" style={buttonStyle}>
                                        Get on the case
                                    </button>
                                    <div style={boxback}>
                                        <a
                                            style={BhoverStyle}
                                            onMouseEnter={() =>
                                                setBackHoverStyle(
                                                    BacklinkHoverStyle
                                                )
                                            }
                                            onMouseLeave={() =>
                                                setBackHoverStyle(BacklinkStyle)
                                            }
                                            href="https://www.danlee.work/"
                                        >
                                            Back to home
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </>
        )
    }
}

const brandx = {
    width: "48px",
    height: "auto",
    marginBottom: "40px",
}

const formStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    opacity: 1,
    transition: "opacity 1s ease",
    width: "100%",
    background: "#090A0C",
}

const boxtitlex = {
    width: "100%",
    padding: "12px",
    textAlign: "center",
}

const boxform = {
    width: "100%",
    padding: "8px",
}

const boxback = {
    width: "100%",
    padding: "40px 24px 24px 24px",
    textAlign: "center",
}

const titlex = {
    fontSize: "24px",
    color: "#fff",
    marginBottom: "16px",
    textAlign: "center",
}

const subtitlex = {
    fontSize: "16px",
    color: "#878889",
    marginBottom: "40px",
}

const linkStyle = {
    fontSize: "16px",
    color: "#fff",
    textDecorationLine: "underline",
    transition: "color 0.2s",
}

const linkHoverStyle = {
    color: "#6A45FF",
    transition: "color 0.2s",
}

const BacklinkStyle = {
    fontSize: "16px",
    color: "#fff",
    textDecorationLine: "underline",
    transition: "color 0.2s",
}

const BacklinkHoverStyle = {
    fontSize: "16px",
    color: "#6A45FF",
    transition: "color 0.2s",
}

const inputStyle = {
    padding: "12px 24px",
    borderRadius: "8px",
    border: "1px solid #ccc",
    borderRight: "none",
    fontSize: "16px",
    width: "100%",
    textAlign: "center",
    maxWidth: "100%",
    height: "45px",
    outline: "none",
    boxShadow:
        "rgba(0, 0, 0, 0.26) 0px 0.637473px 1.147451px -1.125px, rgba(0, 0, 0, 0.24) 0px 1.930942px 3.475695px -2.25px, rgba(0, 0, 0, 0.192) 0px 5.104227px 9.187608px -3.375px, rgba(0, 0, 0, 0.03) 0px 16px 28.8px -4.5px",
}

const errorMessageStyle = {
    color: "red",
    fontSize: "16px",
    padding: "16px",
}

const buttonStyle = {
    padding: "12px 24px",
    borderRadius: "8px",
    border: "none",
    background: "#0033FF",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "bold",
    cursor: "pointer",
    boxShadow: "none",
    height: "45px",
    width: "100%",
    maxWidth: "100%",
}

const wrapperStyle = {
    borderRadius: "0px",
}
